import {
  FaYoutube,
  FaLinkedinIn,
  FaFacebookF,
  FaMedium,
  FaCartShopping,
} from "react-icons/fa6";

export const socialData = [
  {
    icon: <FaFacebookF />,
    link: "https://www.facebook.com/kwstn2",
  },
  {
    icon: <FaLinkedinIn />,
    link: "https://www.linkedin.com/company/kwstn1",
  },
  {
    icon: <FaYoutube />,
    link: "https://www.youtube.com/@kwstn",
  },
];
